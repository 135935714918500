// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-packages-js": () => import("./../src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-payment-js": () => import("./../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-review-js": () => import("./../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-review-success-js": () => import("./../src/pages/review-success.js" /* webpackChunkName: "component---src-pages-review-success-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-default-js": () => import("./../src/templates/Default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-package-js": () => import("./../src/templates/Package.js" /* webpackChunkName: "component---src-templates-package-js" */),
  "component---src-templates-profile-js": () => import("./../src/templates/Profile.js" /* webpackChunkName: "component---src-templates-profile-js" */)
}

